/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BraintreeInstance } from '../../../../services/Braintree';
import { MagentoData } from '../../../../services/MagentoApi';

import formStyles from '../../../../styles/input.module.scss';
import styles from './style.module.scss';
import PaymentIcons from '../../PaymentIcons';
import Button from '../../Button';

const BraintreeVault = (props) => {
  const isDev = (process.env.NODE_ENV === 'development');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [savedCard, setSavedCard] = useState({ publicHash: '', nonceUrl: '' });
  const [methodReady, setMethodReady] = useState(false);

  const {
    emailAddress,
    paymentMethod,
    billingAddress,
    setLoading,
    setCheckoutSuccess,
    savedCards,
    grandTotal,
  } = props;

  const payNow = async () => {
    setError(false);
    setLoading(true);

    const {
      nonceUrl,
      publicHash,
    } = savedCard;

    const email = emailAddress;
    // Get the Nonce
    const paymentNonceRequest = await MagentoData.getSavedCardNonce(nonceUrl, publicHash);

    if (paymentNonceRequest.status === 200) {
      const { data: { paymentMethodNonce = '' } = {} } = paymentNonceRequest;

      const threeDInnerContainer = document.createElement('div');
      threeDInnerContainer.className = 'braintree-3dsecure-popup';

      await BraintreeInstance.createClient().then(
        (clientInstance) => BraintreeInstance.createThreeDSecureInstance(clientInstance),
      );

      const threeDSecureResponse = await BraintreeInstance.threeDSecureInstance.verifyCard({
        amount: parseFloat(grandTotal).toFixed(2),
        nonce: paymentMethodNonce,
        addFrame(err, iframe) {
          // Set up your UI and add the iframe.
          threeDInnerContainer.appendChild(iframe);
          document.body.appendChild(threeDInnerContainer);
          document.body.classList.add('_with-3d-secure');
        },
        removeFrame() {
          document.body.removeChild(threeDInnerContainer);
          document.body.classList.remove('_with-3d-secure');
        },
        onLookupComplete(data, next) {
          next();
        },
      });

      const liability = {
        shifted: threeDSecureResponse.liabilityShifted,
        shiftPossible: threeDSecureResponse.liabilityShiftPossible,
      };

      // eslint-disable-next-line no-mixed-operators
      if (liability.shifted || !liability.shifted && !liability.shiftPossible) {
        const paymentResponse = await MagentoData.submitPayment(
          {
            payment_method_nonce: threeDSecureResponse.nonce,
            public_hash: publicHash,
            is_active_payment_token_enabler: true,
          },
          email,
          billingAddress,
          paymentMethod,
        );

        if (paymentResponse.status === 200) {
          setSuccess(true);
          setCheckoutSuccess();
        } else {
          setError(true);
          setLoading(false);
        }
      } else {
        setError(true);
        setLoading(false);
      }
    } else {
      setError(true);
      setLoading(false);
    }
  };

  const getPaymentIcon = (code) => {
    let paymentIcons = [];

    switch (code) {
      case 'VI':
        paymentIcons = ['visa'];
        break;
      case 'MC':
        paymentIcons = ['mastercard'];
        break;
      case 'none':
        paymentIcons = [''];
        break;
      default:
        break;
    }

    return paymentIcons;
  };


  const setCardAsMethod = (card) => {
    setSavedCard(card);

    // Ensure we have the hash/nonceurl
    if (card.publicHash && card.nonceUrl) {
      setMethodReady(true);
    }
  };

  return (
    <div>
      <>
        {(error) && (
          <div className="Alert AlertError">
            Sorry, your order was unsuccessful.
          </div>
        )}
        {!methodReady && (
          <ul className={styles.savedCards}>
            {savedCards.map((card) => (
              <li className={styles.savedCardsItem}>
                <p className={styles.cardDetails}>
                  <span>{`**** **** **** ${card.details.maskedCC}`}</span>
                  <span>{`Expires: ${card.details.expirationDate}`}</span>
                  <PaymentIcons icons={getPaymentIcon(card.details.type)} />
                </p>
                <button
                  type="submit"
                  name="paymentMethods"
                  className="action ghost"
                  onClick={() => setCardAsMethod(card)}
                >
                  Select card
                </button>
              </li>
            ))}
          </ul>
        )}
        {methodReady && (
          <>
            <p className={styles.cardDetails}>
              <strong>Pay With: </strong>
              <span>{`**** **** **** ${savedCard.details.maskedCC}`}</span>
              <span>{`Expires: ${savedCard.details.expirationDate}`}</span>
              <PaymentIcons icons={getPaymentIcon(savedCard.details.type)} isInCheckout />
              <Button label="Change card" buttonStyle="link" type="button" trigger={() => { setMethodReady(false); }} />
            </p>
            <div className={formStyles.formActions}>
              {(!success) && <button type="submit" onClick={() => payNow()}>Order now</button>}
              {(success) && (<p>Success! Please wait...</p>)}
              {(success && isDev) && (<p>Dev mode: Please manually clear your quote from browser data.</p>)}
            </div>
          </>
        )}
        {(savedCards.length === 0) && (
          <p>You do not have any saved cards.</p>
        )}
      </>
    </div>
  );
};

BraintreeVault.propTypes = {
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  setLoading: PropTypes.func,
  setCheckoutSuccess: PropTypes.func,
  savedCards: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.string,
      publicHash: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  grandTotal: PropTypes.number,
};

BraintreeVault.defaultProps = {
  paymentMethod: {
    code: '',
  },
  billingAddress: {},
  emailAddress: '',
  setLoading: () => {},
  setCheckoutSuccess: () => {},
  savedCards: [],
  grandTotal: 0,
};

export default BraintreeVault;
